<template>
  <!-- eslint-disable max-len -->
  <div class="relative h-full">
    <sidenav />
    <div class="relative lg:ml-64 flex flex-col min-h-full">
      <topbar />
      <header class="border-b border-gray-200 bg-white">
        <div class="flex flex-col lg:flex-row gap-4 lg:gap-5 px-4 lg:px-6 pt-3 lg:pb-5">
          <div class="flex flex-grow items-center">
            <div class="flex-shrink-0">
              <img class="w-btn mr-3" src="brand-icon-view-grid.svg" />
            </div>
            <h1 class="font-bold text-xl">Styleguide</h1>
          </div>
        </div>
      </header>
      <div class="py-5 px-4 lg:px-6 flex-grow flex flex-col space-y-5 bg-gray-100">

        <div class="bg-white border rounded-lg p-4">
          <ul class="space-y-4">
            <li class="rounded-md border">
              <div class="flex items-center justify-between py-3 border-b px-4">
                <div>
                  Accordion item open
                </div>
                <button class="h-6 w-6 inline-flex justify-center items-center rounded-full">
                  <ChevronUpIcon size="20"/>
                </button>
              </div>
              <div class="p-4">
                Content
              </div>
              <div class="border-t space-y-2 bg-gray-100 py-3 px-4 rounded-b-md">
                Footer
              </div>
            </li>

            <li class="rounded-md border cursor-pointer hover:bg-primary-100 hover:border-primary-300" v-for="(n, index) in 3" :key="index">
              <div class="flex items-center justify-between py-3 px-4">
                <div>
                  Accordion item
                </div>
                <button class="h-6 w-6 inline-flex justify-center items-center rounded-full">
                  <ChevronDownIcon size="20"/>
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div class="bg-white border rounded-lg p-4">
          <div class="border divide-y divide-gray-200 rounded-md">
            <div class="lg:grid lg:grid-cols-12 lg:items-center">
              <div class="col-span-3 px-4 pt-4 lg:pt-0">
                <span class="font-semibold">Label</span>
              </div>
              <div class="col-span-9 p-4">
                List-group
              </div>
            </div>
            <div class="lg:grid lg:grid-cols-12 lg:items-center">
              <div class="col-span-3 px-4 pt-4 lg:pt-0">
                <span class="font-semibold">Label</span>
              </div>
              <div class="col-span-9 p-4">
                Ut massa tortor, volutpat ut molestie a, placerat suscipit felis. Etiam eu enim a eros convallis scelerisque vel non magna. Curabitur nec vehicula lorem, vitae cursus diam.
              </div>
            </div>
            <div class="lg:grid lg:grid-cols-12 lg:items-center">
              <div class="col-span-3 px-4 pt-4 lg:pt-0">
                <span class="font-semibold">Label</span>
              </div>
              <div class="col-span-9 p-4">
                Content
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white border rounded-lg p-4">
          <div class="flex gap-5">
            <div class="w-1/2">
              <div class="stats-box is-active">
                <div class="text-gray-500 mb-1">
                  Vendas
                </div>
                <div class="font-bold text-2xl">
                  R$ 235.4K
                </div>
                <span class="inline-flex items-center text-emerald-700 mt-1">
                  <span class="bg-emerald-100 w-5 h-5 rounded-full flex items-center justify-center">
                    <ArrowUpIcon size="14"/>
                  </span>
                  <span class="font-semibold ml-1.5 text-sm">25%</span>
                </span>
              </div>
            </div>
            <div class="w-1/2">
              <div class="stats-box">
                <div class="text-gray-500 mb-1">
                  Contratos
                </div>
                <div class="font-bold text-2xl">
                  346
                </div>
                <span class="inline-flex items-center text-gray-500 mt-1">
                  <span class="bg-gray-200 w-5 h-5 rounded-full flex items-center justify-center">
                    <ArrowUpIcon size="14"/>
                  </span>
                  <span class="font-semibold ml-1.5 text-sm">25%</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white border rounded-lg p-4">
          <div class="pill-tabs">
            <button class="is-active button button-xs hover:text-primary-700">
              Dia
            </button>
            <button class="button button-xs hover:text-primary-700">
              Semana
            </button>
            <button class="button button-xs hover:text-primary-700">
              Mês
            </button>
          </div>
        </div>

        <div class="bg-white border rounded-lg p-4">
          <div class="flex items-center gap-9">
            <div class="space-x-5">
              <label class="inline-flex">
                <input type="checkbox" class="checkbox">
                <span class="ml-2">Checkbox</span>
              </label>

              <label class="inline-flex">
                <input type="checkbox" checked class="checkbox">
                <span class="ml-2">Checkbox</span>
              </label>
            </div>

            <div class="space-x-5">
              <label class="inline-flex">
                <input type="radio" name="radioGroup" class="radio-button">
                <span class="ml-2">Radio</span>
              </label>

              <label class="inline-flex">
                <input type="radio" name="radioGroup" checked class="radio-button">
                <span class="ml-2">Radio</span>
              </label>
            </div>

            <div class="space-x-5">
              <label for="switchToggle" class="switch">
                <!-- toggle -->
                <div class="relative">
                  <!-- input -->
                  <input type="checkbox" id="switchToggle" class="sr-only">
                  <!-- line -->
                  <div class="switch-knob"></div>
                  <!-- dot -->
                  <div class="switch-dot"></div>
                </div>
                <!-- label -->
                <div class="ml-2">
                  Switch
                </div>
              </label>

              <label for="switchToggleB" class="switch">
                <!-- toggle -->
                <div class="relative">
                  <!-- input -->
                  <input type="checkbox" checked id="switchToggleB" class="sr-only">
                  <!-- line -->
                  <div class="switch-knob"></div>
                  <!-- dot -->
                  <div class="switch-dot"></div>
                </div>
                <!-- label -->
                <div class="ml-2">
                  Switch
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="bg-white border rounded-lg p-4 h-72">
          <div class="grid grid-cols-12 gap-5">
            <div class="col-span-3">
              <div class="select dropdown">
                <button class="button dropdown-toggle">
                  <span class="text-gray-500 mr-1.5">Label</span>
                  <span>Select</span>
                </button>
                <div class="dropdown-menu">
                  <div class="dropdown-menu-list">
                    <div class="dropdown-menu-item is-active">
                      <button class="dropdown-menu-button">
                        <span>Item</span>
                      </button>
                    </div>
                    <div class="dropdown-menu-item" v-for="(n, index) in 2" :key="index">
                      <button class="dropdown-menu-button">
                        <span>Item</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-3">
              <div class="dropdown select">
                <button class="button dropdown-toggle">
                  <span>Select</span>
                </button>
                <div class="dropdown-menu pb-0">
                  <div class="px-2 pb-2">
                    <div class="input-group has-icon">
                      <div class="input-control">
                        <div class="input-icon">
                          <SearchIcon size="20"/>
                        </div>
                        <input type="text" class="input-field h-9" placeholder="Search" />
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-menu-list max-h-36 overflow-y-auto">
                    <div class="dropdown-menu-list">
                      <div class="dropdown-menu-item is-active">
                        <button class="dropdown-menu-button">
                          <span>Item</span>
                        </button>
                      </div>
                      <div class="dropdown-menu-item" v-for="(n, index) in 10" :key="index">
                        <button class="dropdown-menu-button">
                          <span>Item</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-3">
              <div class="dropdown">
                <button class="button dropdown-toggle">
                  <span>Dropdown</span>
                </button>
                <div class="dropdown-menu">
                  <div class="dropdown-menu-list">
                    <div class="dropdown-menu-item" v-for="(n, index) in 3" :key="index">
                      <button class="dropdown-menu-button">
                        <span>Item</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-3">
              <div class="dropdown">
                <button class="button dropdown-toggle">
                  <span>Dropdown</span>
                </button>
                <div class="dropdown-menu">
                  <div class="dropdown-menu-list">
                    <div class="dropdown-menu-item" v-for="(n, index) in 3" :key="index">
                      <button class="dropdown-menu-button">
                        <CogIcon class="mr-2" size="24"/>
                        <span>Item</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white border rounded-lg p-4">
          <ul class="space-y-5 max-w-xs">
            <li>
              <label class="form-label">Label</label>
              <div class="input-group">
                <div class="input-control">
                  <input type="text" class="input-field" placeholder="Text" />
                </div>
              </div>
            </li>
            <li>
              <div>
                <div class="input-group has-icon-group">
                  <div class="input-control">
                    <input type="text" class="input-field" placeholder="Loading" />
                    <div class="input-icon-group p-2 lg:p-1">
                      <svg role="status" class="w-6 h-6 text-gray-500 animate-spinner" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="opacity-30" d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="dropdown select">
                <button class="button select-toggle">Select</button>
              </div>
            </li>
            <li>
              <div class="input-group has-icon">
                <div class="input-control">
                  <div class="input-icon">
                    <SearchIcon size="24"/>
                  </div>
                  <input type="text" class="input-field" placeholder="Search" />
                </div>
              </div>
            </li>
            <li>
              <div class="input-group has-addon flex">
                <div class="input-addon bg-gray-200 rounded-l-md">
                  <div class="select dropdown">
                    <button class="button button-sm dropdown-toggle">
                      <span>Dropdown</span>
                    </button>
                    <div class="dropdown-menu hidden">
                      <div class="dropdown-menu-list">
                        <div class="dropdown-menu-item is-active">
                          <button class="dropdown-menu-button">
                            <span>Item</span>
                          </button>
                        </div>
                        <div class="dropdown-menu-item">
                          <button class="dropdown-menu-button">
                            <span>Item</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="input-control">
                  <input type="text" class="input-field" placeholder="Text" />
                </div>
              </div>
            </li>
            <li>
              <div class="color-swatch input-group lg:w-48">
                <div class="input-control">
                  <div class="input-group-color"></div>
                  <input type="text" class="input-field" placeholder="#FFFFFF"/>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <nav aria-label="Breadcrumb" class="breadcrumb">
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
               <li>
                <a href="#">Page name</a>
              </li>
              <li>
                <span>Current page</span>
              </li>
            </ol>
          </nav>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <div class="callout">
            <div class="flex-shrink-0 w-6 mr-3">
              <CheckCircleIcon size="24"/>
            </div>
            <div class="flex-grow">Message here</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>

          <div class="callout callout-success">
            <div class="flex-shrink-0 w-6 mr-3">
              <CheckCircleIcon size="24"/>
            </div>
            <div class="flex-grow">Message here</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>

          <div class="callout callout-danger">
            <div class="flex-shrink-0 w-6 mr-3">
              <MinusCircleIcon size="24"/>
            </div>
            <div class="flex-grow">Message here</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>

          <div class="callout callout-warning">
            <div class="flex-shrink-0 w-6 mr-3">
              <ExclamationCircleIcon size="24"/>
            </div>
            <div class="flex-grow">Message here</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>

          <div class="callout callout-info">
            <div class="flex-shrink-0 w-6 mr-3 flex justify-center items-center">
              <InformationCircleIcon size="24"/>
            </div>
            <div class="flex-grow">Message here</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <div class="toast">
            <div class="flex-grow">Message here</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>

          <div class="toast toast-success">
            <div class="flex-shrink-0 w-6 mr-3">
              <CheckCircleIcon size="24"/>
            </div>
            <div class="flex-grow">Message here</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>

          <div class="toast toast-danger">
            <div class="flex-shrink-0 w-6 mr-3">
              <MinusCircleIcon size="24"/>
            </div>
            <div class="flex-grow">Message here</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>

          <div class="toast toast-warning">
            <div class="flex-shrink-0 w-6 mr-3">
              <ExclamationCircleIcon size="24"/>
            </div>
            <div class="flex-grow">Message here</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>

          <div class="toast toast-info">
            <div class="flex-shrink-0 w-6 mr-3">
              <InformationCircleIcon size="24"/>
            </div>
            <div class="flex-grow">Message</div>
            <button
              aria-label="Close"
              class="button-icon button-xs"
              @click="showModal"
            >
              <XIcon size="20"/>
            </button>
          </div>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <span class="chip">
            <span class="icon-circle"></span>
            <span>Chip</span>
          </span>

          <span class="chip chip-success">
            <span class="icon-circle"></span>
            <span>Chip</span>
          </span>

          <span class="chip chip-danger">
            <span class="icon-circle"></span>
            <span>Chip</span>
          </span>

          <span class="chip chip-warning">
            <span class="icon-circle"></span>
            <span>Chip</span>
          </span>

          <span class="chip chip-info">
            <span class="icon-circle"></span>
            <span>Chip</span>
          </span>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <span class="badge">
            <span>Badge</span>
          </span>
          <span class="badge pl-1">
            <InformationCircleIcon size="20"/>
            <span>Badge</span>
          </span>

          <span class="badge badge-success">
            <span>Badge</span>
          </span>
          <span class="badge badge-success pl-1">
            <InformationCircleIcon size="20"/>
            <span>Badge</span>
          </span>

          <span class="badge badge-danger">
            <span>Badge</span>
          </span>
          <span class="badge badge-danger pl-1">
            <InformationCircleIcon size="20"/>
            <span>Badge</span>
          </span>

          <span class="badge badge-warning">
            <span>Badge</span>
          </span>
          <span class="badge badge-warning pl-1">
            <InformationCircleIcon size="20"/>
            <span>Badge</span>
          </span>

          <span class="badge badge-info">
            <span>Badge</span>
          </span>
          <span class="badge badge-info pl-1">
            <InformationCircleIcon size="20"/>
            <span>Badge</span>
          </span>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <button class="button button-pill button-primary-subtle">
            <DownloadIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-pill">
            Button
          </button>
          <button class="button button-xs button-pill dropdown-toggle">
            Button
          </button>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <button class="button">
            <span>Button</span>
          </button>
          <button class="button pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>

          <button class="button button-sm">
            <span>Button</span>
          </button>
          <button class="button button-sm pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-sm pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-sm button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <button class="button button-link">
            <span>Button</span>
          </button>
          <button class="button button-link pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-link pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-link button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>

          <button class="button button-link button-sm">
            <span>Button</span>
          </button>
          <button class="button button-link button-sm pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-link button-sm pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-link button-sm button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <button class="button button-link-subtle">
            <span>Button</span>
          </button>
          <button class="button button-link-subtle pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-link-subtle pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-link-subtle button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>

          <button class="button button-link-subtle button-sm">
            <span>Button</span>
          </button>
          <button class="button button-link-subtle button-sm pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-link-subtle button-sm pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-link-subtle button-sm button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <button class="button button-primary">
            <span>Button</span>
          </button>
          <button class="button button-primary pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-primary pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-primary button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>
          <button class="button button-primary pl-3 cursor-not-allowed">
            <svg role="status" class="w-6 h-6 text-white animate-spinner" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="opacity-30" d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
            </svg>
            <span>Button loading</span>
          </button>

          <button class="button button-primary button-sm">
            <span>Button</span>
          </button>
          <button class="button button-primary button-sm pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-primary button-sm pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-primary button-sm button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <button class="button button-primary-subtle">
            <span>Button</span>
          </button>
          <button class="button button-primary-subtle pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-primary-subtle pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-icon button-primary-subtle">
            <DotsHorizontalIcon size="20"/>
          </button>

          <button class="button button-primary-subtle button-sm">
            <span>Button</span>
          </button>
          <button class="button button-primary-subtle button-sm pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-primary-subtle button-sm pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-primary-subtle button-sm button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>
        </div>

        <div class="flex items-start bg-white border rounded-lg p-4 gap-5 flex-wrap">
          <button class="button button-danger-subtle">
            <span>Button</span>
          </button>
          <button class="button button-danger-subtle pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-danger-subtle pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-icon button-danger-subtle">
            <DotsHorizontalIcon size="20"/>
          </button>

          <button class="button button-danger-subtle button-sm">
            <span>Button</span>
          </button>
          <button class="button button-danger-subtle button-sm pl-2">
            <PlusIcon size="20"/>
            <span>Button</span>
          </button>
          <button class="button button-danger-subtle button-sm pr-2">
            <span>Button</span>
            <ChevronRightIcon size="20"/>
          </button>
          <button class="button button-danger-subtle button-sm button-icon">
            <DotsHorizontalIcon size="20"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DownloadIcon, SearchIcon, CogIcon, ArrowUpIcon } from '@vue-hero-icons/outline';
import { ExclamationCircleIcon, MinusCircleIcon, ChevronRightIcon, PlusIcon, XIcon, DotsHorizontalIcon, InformationCircleIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@vue-hero-icons/solid';
import Sidenav from '../../Sidenav.vue';
import Topbar from '../../Topbar.vue';

export default {
  name: 'styleguide',
  metaInfo: {
    title: 'Styleguide',
  },
  components: {
    ChevronRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CogIcon,
    ArrowUpIcon,
    SearchIcon,
    DownloadIcon,
    ExclamationCircleIcon,
    MinusCircleIcon,
    XIcon,
    DotsHorizontalIcon,
    CheckCircleIcon,
    PlusIcon,
    InformationCircleIcon,
    Sidenav,
    Topbar,
  },
  data: () => ({
    openModal: false,
  }),
  methods: {
    showModal() {
      this.openModal = !this.openModal;
    },
  },
  mounted() {},
};
</script>
